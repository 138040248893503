import ApiClient from '../api'
import { CallCenter } from '../models'
import axios from '../plugins/axios'

const api = new ApiClient(CallCenter.modelName)

export const getEmployees = async (params) => {
  const { data: employees } = await api.getAll(params)

  return employees
}

export const getEmployee = async (id) => {
  const { data: employee } = await api.getOne(id)

  return employee
}

export const createEmployee = async (formData) => {
  const { data: employee } = await api.create(formData)

  return employee
}

export const updateEmployee = async (formData) => {
  const { data: employee } = await api.update(formData)

  return employee
}

export const removeEmployee = async (id) => {
  return await api.delete(id)
}

export const getLanguages = async () => {
  return await axios.get('/admin/langs')
}
