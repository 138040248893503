<template>
  <div>
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header">
            <div class="d-flex justify-content-between align-items-center">
              <div class="header-title me-5">
                <h4 class="card-title">Сотрудники Call center</h4>
              </div>
              <button
                type="button"
                class="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#add-button"
              >
                Добавить
              </button>
            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table" role="grid" data-toggle="data-table">
                <thead>
                <tr class="ligth">
                  <th data-type="number" v-for="(header, index) in tableHeaders" :key="index">{{ header }}</th>
                </tr>
                </thead>
                <tr v-for="employeeData in employees" :key="employeeData.id">
                  <td>
                    {{ employeeData.full_name }}
                  </td>
                  <td>
                    {{ employeeData.phone }}
                  </td>
                  <td>
                    <a :href="`https://t.me/${employeeData.user_name}`">{{ employeeData.user_name }}</a>
                  </td>
                  <td>
                    {{ employeeData.lang }}
                  </td>
                  <td class="text-center">
                    <div class="dropdown">
                      <a class="btn btn-primary dropdown-toggle" href="#" role="button" id="dropdownMenuLink"
                         data-bs-toggle="dropdown" aria-expanded="false">
                        Опции
                      </a>
                      <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                        <li>
                          <a class="dropdown-item" href="#" data-bs-toggle="modal"
                             data-bs-target="#update" @click.prevent="selectEmployee(employeeData.id)">Редактировать</a>
                        </li>
                        <li><a class="dropdown-item text-danger" href="#"
                               @click.prevent="deleteEmployee(employeeData.id)">Удалить</a></li>
                      </ul>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>

          <!--          <div class="card-footer d-flex justify-content-center">-->
          <!--            <div class="bd-content">-->
          <!--              <CustomPagination-->
          <!--                :total-pages="20"-->
          <!--                :total="200"-->
          <!--                :per-page="10"-->
          <!--                :current-page="currentPage"-->
          <!--                @pagechanged="onPageChange"-->
          <!--              />-->
          <!--            </div>-->
          <!--          </div>-->
        </div>
      </div>
    </div>
    <!--    create modal-->
    <modal
      ref="ordersModal"
      mainClass="fade"
      :tabindex="-1"
      id="add-button"
      ariaLabelled="addModalLabel"
      :ariaHidden="true"
      dialog-class="modal-lg modal-dialog-scrollable"
    >
      <model-header :dismissable="true"></model-header>

      <model-body class="px-5">
        <h1 class="text-center">Новый сотрудник Call center</h1>

        <Form v-slot="{ meta }" class="row my-5" @submit="onSubmit">
          <div class="col-md-6">
            <label for="fullName" class="form-label">ФИО</label>
            <Field id="fullName" type="text" class="form-control" name="full_name" rules="required" />
            <ErrorMessage name="full_name" class="text-danger" />
          </div>

          <div class="col-md-6">
            <label for="phoneNumber" class="form-label">Номер телефона</label>
            <Field id="phoneNumber" type="text" class="form-control" name="phone" rules="required" placeholder="998" />
            <ErrorMessage name="phone" class="text-danger" />
          </div>

          <div class="my-2"></div>

          <div class="col-md-6">
            <label for="userName" class="form-label">Телеграм username</label>
            <Field id="userName" type="text" class="form-control" name="user_name" rules="required"
                   placeholder="user name" />
            <ErrorMessage name="user_name" class="text-danger" />
          </div>

          <div class="col-md-6">
            <label for="language-code" class="form-label">Язык</label>
            <Field id="language-code" as="select" class="form-control" name="lang" rules="required">
              <option
                v-for="(language, index) in languages"
                :key="index"
                :value="language.lang_code"
              >
                {{ language.lang_name }}
              </option>
            </Field>
            <ErrorMessage name="lang" class="text-danger" />
          </div>

          <div class="my-4"></div>

          <div class="col-md-12 text-center">
            <button type="submit" class="btn btn-primary" :disabled="!(meta.valid && meta.dirty)">Сохранить</button>
          </div>
        </Form>

      </model-body>
    </modal>

    <modal
      mainClass="fade"
      :tabindex="-1"
      id="update"
      ariaLabelled="addModalLabel"
      :ariaHidden="true"
      dialog-class="modal-lg modal-dialog-scrollable"
    >
      <model-header :dismissable="true"></model-header>

      <model-body v-if="employee !== null" class="px-5">
        <h1 class="text-center">Редактировать {{ employee?.id }}</h1>

        <Form v-slot="{ meta }" class="row my-5" @submit="onUpdate">
          <div class="col-md-6">
            <label for="fullName" class="form-label">ФИО</label>
            <Field
              v-model="employee.full_name"
              id="fullName"
              type="text"
              class="form-control"
              name="full_name"
              rules="required"
            />
            <ErrorMessage name="full_name" class="text-danger" />
          </div>

          <div class="col-md-6">
            <label for="phoneNumber" class="form-label">Номер телефона</label>
            <Field id="phoneNumber" type="text" class="form-control" name="phone" rules="required" placeholder="998"
                   v-model="employee.phone" />
            <ErrorMessage name="phone" class="text-danger" />
          </div>

          <div class="my-2"></div>

          <div class="col-md-6">
            <label for="userNamTg" class="form-label">Телеграм username</label>
            <Field id="userNameTg" type="text" class="form-control" name="user_name" rules="required"
                   placeholder="user_name" v-model="employee.user_name" />
            <ErrorMessage name="user_name" class="text-danger" />
          </div>

          <div class="col-md-6">
            <label for="language" class="form-label">Язык</label>
            <Field id="language" as="select" class="form-control" name="lang" rules="required"
                   v-model="employee.lang"
            >
              <option
                v-for="(language, index) in languages"
                :key="index"
                :value="language.lang_code"
              >
                {{ language.lang_name }}
              </option>
            </Field>
            <ErrorMessage name="lang" class="text-danger" />
          </div>

          <div class="my-4"></div>

          <div class="col-md-12 text-center">
            <button type="submit" class="btn btn-primary" :disabled="!(meta.valid && meta.dirty)">Сохранить</button>
          </div>
        </Form>
      </model-body>
    </modal>
  </div>
</template>

<script>
import modal from '@/components/bootstrap/modal/modal'
import modelHeader from '@/components/bootstrap/modal/model-header'
import modelBody from '@/components/bootstrap/modal/model-body'

import { Form, Field, ErrorMessage } from 'vee-validate'

import { getEmployees, createEmployee, updateEmployee, removeEmployee, getLanguages } from '@/services/call-center.service'

export default {
  name: 'ButtonsIndex',
  data: () => ({
    tableHeaders: [
      'ФИО',
      'Номер телефона',
      'Телеграм username',
      'Язык',
      'Действия'
    ],
    employees: [],
    employeeData: null,
    languages: []
  }),
  computed: {
    employee () {
      return this.employeeData
    }
  },
  components: {
    modal,
    modelBody,
    modelHeader,
    Form,
    Field,
    ErrorMessage
  },
  methods: {
    selectEmployee (id) {
      this.employeeData = this.employees.find(employee => employee.id === Number(id))
    },
    async fetchEmployees () {
      const { data: employees } = await getEmployees()
      this.employees = employees
    },
    async fetchLanguages () {
      const { data: languages } = await getLanguages()
      this.languages = languages
    },
    async deleteEmployee (id) {
      if (confirm('Are you sure delete')) {
        await removeEmployee(id)
        await this.fetchEmployees()
      }
    },
    async onSubmit (values) {
      const formData = new FormData()

      Object.entries(values).forEach(([field, value]) => {
        formData.append(field, value)
      })

      await createEmployee(formData)

      window.location.reload()
    },
    async onUpdate (values) {
      const formData = new FormData()

      formData.append('id', this.employee.id)
      Object.entries(values).forEach(([field, value]) => {
        formData.append(field, value)
      })

      await updateEmployee(formData)

      window.location.reload()
    }
  },
  async created () {
    await this.fetchEmployees()
    await this.fetchLanguages()
  }
}
</script>
